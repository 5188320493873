import auth from '@/middleware/auth.js'

export default [
	{
		path: ''
		,component: () => import('@/layouts/Constelacion')
		,children: [
			{
				path: '/analyticpoint'
				,name: 'analyticpoint'
				,component: () => import('@/apps/analyticpoint/pages/Dashboard')
				,meta: {
					title: 'Comité'
					,middleware: auth
				}
			},
			{
				path: '/analyticpoint/solicitudes'
				,name: 'analyticpoint-solicitudes'
				,component: () => import('@/apps/analyticpoint/pages/Solicitud')
				,meta: {
					title: 'Solicitudes - Comité'
					,middleware: auth
				}
			},
			{
				path: '/analyticpoint/solicitudes/:solicitud'
				,name: 'analyticpoint-solicitudes-solicitud'
				,component: () => import('@/apps/analyticpoint/pages/Solicitud/Visualizador')
				,meta: {
					title: 'Solicitudes - Comité'
					,middleware: auth
				}
			},
			{
				path: '/analyticpoint/historico/detalle/:solicitud'
				,name: 'analyticpoint-historico-detalle'
				,component: () => import('@/apps/analyticpoint/pages/Historico/Info')
				,meta: {
					title: 'Solicitud - Histórico - Comité'
					,middleware: auth
				}
			},
			{
				path: '/analyticpoint/historico'
				,name: 'analyticpoint-historico'
				,component: () => import('@/apps/analyticpoint/pages/Historico')
				,meta: {
					title: 'Histórico - Comité'
					,middleware: auth
				}
			},
			{
				path: '/analyticpoint/cesiones'
				,name: 'analyticpoint-cesiones'
				,component: () => import('@/apps/analyticpoint/pages/Cesion')
				,meta: {
					title: 'Cesiones - Comité'
					,middleware: auth
				}
			},
			{
				path: '/analyticpoint/cesiones/historico'
				,name: 'analyticpoint-cesiones-historico'
				,component: () => import('@/apps/analyticpoint/pages/Cesion/Historico')
				,meta: {
					title: 'Histórico - Cesiones - Comité'
					,middleware: auth
				}
			},
			{
				path: '/analyticpoint/alianzas'
				,name: 'analyticpoint-alianzas'
				,component: () => import('@/apps/analyticpoint/pages/Alianza')
				,meta: {
					title: 'Alianzas - Comité'
					,middleware: auth
				}
			},
			{
				path: '/analyticpoint/admin/solicitudes'
				,name: 'analyticpoint-admin-solicitudes'
				,component: () => import('@/apps/analyticpoint/pages/Admin/Solicitud')
				,meta: {
					title: 'Administrador - Solicitudes - Comité'
					,middleware: auth
				}
			},
			{
				path: '/analyticpoint/admin/configuracion'
				,name: 'analyticpoint-admin-configuracion'
				,component: () => import('@/apps/analyticpoint/pages/Admin/Configuracion')
				,meta: {
					title: 'Administrador - Configuración - Comité'
					,middleware: auth
				}
			},
			{
				path: '/analyticpoint/admin/acreditadores'
				,name: 'analyticpoint-admin-acreditadores'
				,component: () => import('@/apps/analyticpoint/pages/Admin/Acreditador')
				,meta: {
					title: 'Administrador - Acreditadores - Comité'
					,middleware: auth
				}
			},
			{
				path: '/analyticpoint/admin/catalogos'
				,name: 'analyticpoint-admin-catalogos'
				,component: () => import('@/apps/analyticpoint/pages/Admin/Catalogo')
				,meta: {
					title: 'Administrador - Catálogos - Comité'
					,middleware: auth
				}
			}
		]
	}
];